import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Score } from '../score/Score'
import { ScoreMeta } from "../score/ScoreMeta";
import { useParams } from 'react-router';
import { FETCH_STATUS_FULFILLED, FETCH_STATUSES } from '../score/constants';
import { fetchScoreById } from '../score/scoreSlice';


export default function Editor () {
  const fetch_status = useSelector(state => state.editor.present.score.fetch.status),
        loadedScoreId = useSelector(state => state.editor.present.score.score?.id),
        urlParams = useParams(),
        scoreId = parseInt(urlParams['scoreId']),
        dispatch = useDispatch();

  useEffect(() => {
    if (fetch_status === FETCH_STATUSES.IDLE
      || (fetch_status === FETCH_STATUS_FULFILLED
          && loadedScoreId != scoreId
      )
    )  {
      dispatch(fetchScoreById(scoreId));
    }
  }, [fetch_status]);
  
  if (loadedScoreId != scoreId) {
    return <></>
  }
  else {
    return <div style={{ position: 'relative', flex: '1 0 0' }}>
      <div style={{ position: 'absolute', inset: '0', display: 'flex' }}>
        <div style={{ overflow: 'auto', position: 'relative', padding: '0 2ch', flex: '1 0 0' }}>
          <DndProvider backend={HTML5Backend}>
            <Score /> 
          </DndProvider>
        </div>
        <ScoreMeta />
        <details id="panel--help" class="panel__collapsable">
          <summary>help</summary>
          <iframe src="https://organon.pw/pages/en/aide/"></iframe>
        </details>
      </div>
    </div>
  }
}
