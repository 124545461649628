import { LINE_TYPES, LINK_MODE_DEFAULT, LINK_DEFAULT, TRANSITION_DEFAULT, CONTINGENT_MODES, CONTINGENT_MODE_DEFAULT, SCORE_LANGUAGE_REMAP } from './constants';
import scoreData from './data/23.json'
import { isValidLinkMode, isValidTransition, isValidLineType, isValidLanguage } from './validators'
import { getCSRF } from '../../utils/crsf';
import { APIBaseUrl } from '../organon/settings';
import uuidv4 from '../../utils/uuid'


// function ensureValidPointTo (line) {
//   if ('pointTo' in line) {
//     if (!isValidPointToMode(line.pointTo.mode)) {
//       line.pointTo.mode = POINT_TO_MODE_DEFAULT;
//     }
//   }
//   else {
//     line.pointTo = POINT_TO_DEFAULT;
//   }
// }



/**
 * Lines can have an alternative while it has no sublines.
 * @TODO perhaps include a check in player. Bit tedious? Easy to forget?
 * This can block the player. Therefore strip those alternatives.
 * @param {*} line 
 */
function stripAlternativeOnLinesWithoutSublines (line) {
  if (line.sublines.length === 0) {
    line.alternative = null;
  }

  return line;
}

/**
 * Add ids to lines without
 * @param {*} lines 
 * @returns 
 */
function ensureLineIds (lines) {
  return lines.map(line => {
    if (!('id' in line)) {
      line['id'] = uuidv4();
    }
    
    return line;
  })
}


function ensureValidLink (line) {
  if ('link' in line && line.link) {
    if (!isValidLinkMode(line.link.mode)) {
      line.link.mode = LINK_MODE_DEFAULT;
    }
  }
  else {
    line.link = LINK_DEFAULT
  }

  return line;
}


function ensureValidLineType (line) {
  if (!isValidLineType(line?.type)) {
    line.type = LINE_TYPES.REGULAR;
  }

  return line
}

/**
 * Ensure the line has a valid transition.
 * If no transition was set, use the default value.
 * @param {Line} line 
 */
function ensureValidTransition (line) {
  if (!('transition' in line) || !isValidTransition(line.transition)) {
    line.transition = TRANSITION_DEFAULT;
  }

  return line;
}


/**
 * Ensure the line has a 'complex' value for the contingent field.
 * Try to map existing condition and necessary_when smartly.
 * 
 * Otherwise set type to null
 */
function ensureValidContingent (line) {
  if ('contingent' in line && line['contingent']) {
    if (typeof line['contingent'] == 'boolean') {
      if ('necessary_when' in line && line['necessary_when']) {
        line['contingent'] = {
          mode: CONTINGENT_MODES.OBLIGATION,
          value: line['necessary_when']
        };
      } else if ('condition' in line && line['condition']) {
        line['contingent'] = {
          mode: CONTINGENT_MODES.CONDITION,
          value: line['condition']
        };
      } else {
        line['contingent'] = {
          mode: CONTINGENT_MODE_DEFAULT,
          value: undefined
        }
      }
    }
  }
  else {
    line['contingent'] = false
  }

  return line;
}

/**
 * Ensure the line has an attachments field. If the line has an
 * attachment, add it to the list.
 * @param {*} line 
 */
function ensureAttachments (line) {
  if (!('attachments' in line)) {
    if ('attachment' in line && line['attachment']) {
      line['attachments'] = [line['attachment']];
    }
    else {
      line['attachments'] = [];
    }
  }

  return line;
}

/**
 * Tries to normalize a language value using the remapping set in the
 * constants file.
 * Returns null if it can't resolve the value
 * @param {string} language
 * @returns string|null
 */
function normalizeLanguage (language) {

  if (language in SCORE_LANGUAGE_REMAP) {
    return SCORE_LANGUAGE_REMAP[language];
  }

  return null
}

/**
 * Transforms a line into a list containing the line and its sublines.
 * @param {Line} line 
 * @returns Array
 */
function flattenLine (line) {
  let flattened = line.sublines.flatMap((l) => flattenLine(l));
  flattened.push(line);
  return flattened;
}

// API returns a nested structure
// transform it into a flat lookup table
// and replace children for ID's of children
// will transform data of line to be valid.
function destructureScoreLines (score) {
  let lineIndex = {};

  // 
  const linesFlat = ensureLineIds(flattenLine(score.mainline));

  linesFlat.forEach((line) => {
    line.sublines = line.sublines.map(subline => subline.id);
    lineIndex[line.id] = line;
    line.sublines.forEach((subline) => {
      lineIndex[subline]['parentId'] = line.id;
    });
    line = ensureValidLineType(line);
    line = ensureValidLink(line);
    line = ensureValidTransition(line);
    line = ensureAttachments(line);
    line = ensureValidContingent(line);
    line = stripAlternativeOnLinesWithoutSublines(line);
  });

  score.mainline = score.mainline.id; 

  if (!isValidLanguage(score.language)) {
    score.language = normalizeLanguage(score.language);
  }

  score['lines'] = lineIndex;

  return score;
}

const flattenedScore = destructureScoreLines(scoreData)

export function fetchScore () {
  return flattenedScore;
}

export function fetchScoreById (id) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/scores/${ parseInt(id) }/`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        r.json()
          .then(data => resolve(destructureScoreLines(data)))
          // Attachments slice listens to the score fetching fulfillment to extract attachment urls
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}


export function putScore (score) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/scores/${ parseInt(score.id) }/`,
      { 
        'method': 'PUT',
        'credentials': 'include',
        'body': JSON.stringify(score),
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        if (r.status == 200) {
          r.json()
            .then(data => resolve(data))
            .catch(reject);
        }
        else {
          reject(r.statusText);
        }
      })
      .catch(reject)
  });


  return promise;
}


export function createScore (score) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/scores/`,
      { 
        'method': 'POST',
        'credentials': 'include',
        'body': JSON.stringify(score),
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        if (r.status == 201) {
          r.json()
            .then(data => resolve(data))
            .catch(reject);
        }
        else {
          reject(r.statusText);
        }
      })
      .catch(reject)
  });


  return promise;
}


export function fetchScoreList () {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/scores/?page=1&ordering=title`)
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}

export function fetchMyScoreList () {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/scores/?page=1&ordering=-updated_at&can_edit=true`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}

export default { createScore, fetchScore, fetchScoreById, fetchScoreList, fetchMyScoreList, putScore }

