export const RANGE_TYPES = {
  undetermined: "0",
  exact: "1",
  minimal: "2",
  range: "3"
}

export const RANGE_DEFAULT_TYPE = RANGE_TYPES.undetermined;

export const RANGE_DEFAULT_VALUES = {
  boucle: {
    [RANGE_TYPES.exact]: 2,
    [RANGE_TYPES.minimal]: 2,
    [RANGE_TYPES.range]: [2, 3]
  },
  alternative: {
    [RANGE_TYPES.exact]: 1,
    [RANGE_TYPES.minimal]: 1,
    [RANGE_TYPES.range]: [1, 1]
  }
}

export const DRAG_ITEM_TYPES = {
  LINE: "line",
  PLAYER_LINE: "player-line"
}

export const LINK_MODES = {
  RESUME: "RESUME",  
  KEEP: "KEEP",
  GOTO: "GOTO"
}

export const VALID_LINK_MODES = Object.values(LINK_MODES)

export const LINK_MODE_DEFAULT = LINK_MODES.RESUME

export const LINK_DEFAULT = {
  line: null,
  mode: LINK_MODE_DEFAULT
}

export const POINT_TO_MODES = {
  FEEDS: "FEEDS",
  GOTO: "GOTO"
}

export const VALID_POINT_TO_MODES = Object.values(POINT_TO_MODES)

export const isValidPointToMode = (v) => VALID_POINT_TO_MODES.indexOf(v) > -1

export const POINT_TO_MODE_DEFAULT = POINT_TO_MODES.FEEDS

// export const POINT_TO_DEFAULT = {
//   line: null,
//   mode: POINT_TO_MODE_DEFAULT
// }

export const POINT_TO_DEFAULT = {
  line: null,
  mode: POINT_TO_MODE_DEFAULT
};

export const LINE_TYPE_REGULAR = "REGULAR";
export const LINE_TYPE_LINK = "LINK";

export const LINE_TYPES = {
  REGULAR: LINE_TYPE_REGULAR,
  LINK: LINE_TYPE_LINK
}


export const VALID_LINE_TYPES = Object.values(LINE_TYPES)

export const isValidLineType = (v) => VALID_LINE_TYPES.indexOf(v) > -1

export const TAG_SUCCESSIVE = '>';
export const TAG_SUCCESSIVE_RANDOM = '|';
export const TAG_SIMULTANEOUS = '//';
export const TAG_ACCUMULATIVE = '≥';
export const TAG_ACCUMULATIVE_RANDOM = '||';

export const TAGS = [
  TAG_SUCCESSIVE,
  TAG_SUCCESSIVE_RANDOM,
  TAG_SIMULTANEOUS,
  TAG_ACCUMULATIVE,
  TAG_ACCUMULATIVE_RANDOM
]

export const TAG_DEFAULT = TAG_SUCCESSIVE;

export const ASPECTS = ["duratif", "itératif", "sémelfactif"]

export const NONBREAKINGSPACE = ' '
export const NBSP = NONBREAKINGSPACE
export const INDENT = NBSP.repeat(3)

export const TRANSITIONS = {
  CHAINED: "chained",  
  CROSSFADE: "crossfade",
  CUT: "cut"
}

export const VALID_TRANSITIONS = Object.values(TRANSITIONS)

export const TRANSITION_DEFAULT = TRANSITIONS.CHAINED

export const FETCH_STATUS_IDLE = 'idle'

export const FETCH_STATUS_PENDING = 'pending'

export const FETCH_STATUS_FAILED = 'failed'

export const FETCH_STATUS_FULFILLED = 'fulfilled'

export const FETCH_STATUSES = {
  IDLE: FETCH_STATUS_IDLE,
  PENDING: FETCH_STATUS_PENDING,
  FAILED: FETCH_STATUS_FAILED,
  FULFILLED: FETCH_STATUS_FULFILLED
}

export const DEFAULT_LANGUAGE = 'en'

export const PLAYER_LINE_MODE_SUCCESSIVE = 'successive'

export const PLAYER_LINE_MODE_SIMULTANEOUS = 'simultaneous'

export const PLAYER_LINE_MODE_ACCUMULATIVE = 'accumulative'

export const PLAYER_LINE_MODES = {
  SUCCESSIVE: PLAYER_LINE_MODE_SUCCESSIVE,
  SIMULTANEOUS: PLAYER_LINE_MODE_SIMULTANEOUS,
  ACCUMULATIVE: PLAYER_LINE_MODE_ACCUMULATIVE
}

export const PLAYER_RENDER_MODE_STUB = 'stub'
export const PLAYER_RENDER_MODE_RENDER = 'render'
export const PLAYER_RENDER_MODE_HIDE = 'hide'


export const CONTINGENT_MODES = {
  CONDITION: 'condition',
  OBLIGATION: 'obligation',
  IMPERTATIF: 'imperatif'
}

export const VALID_CONTINGENT_MODES = Object.values(CONTINGENT_MODES)

export const CONTINGENT_MODE_DEFAULT = CONTINGENT_MODES.CONDITION

export const CONTINGENT_DEFAULT = {
  mode: CONTINGENT_MODES.CONDITION,
  value: ''
}

export const UNDOABLE_EDITOR_UNDO = 'UNDOABLE_EDITOR_UNDO';
export const UNDOABLE_EDITOR_REDO = 'UNDOABLE_EDITOR_REDO';
export const UNDOABLE_EDITOR_RESET = 'UNDOABLE_EDITOR_RESET';

export const UNDOABLE_PLAYER_UNDO = 'UNDOABLE_PLAYER_UNDO';
export const UNDOABLE_PLAYER_REDO = 'UNDOABLE_PLAYER_REDO';
export const UNDOABLE_PLAYER_RESET = 'UNDOABLE_PLAYER_RESET';

/*
 * Map of existing values to normalized values
 */
export const SCORE_LANGUAGE_REMAP = {
  'Français': 'fr',
  'français': 'fr',
  'English': 'en',
  'english': 'en'
}

/*
 * Dict with supported language values
 */
export const SCORE_LANGUAGE_VALUES = {
  'fr': 'French',
  'en': 'English'
}

export const SCORE_LANGUAGE_VALID_VALUES = [null].concat(Object.keys(SCORE_LANGUAGE_VALUES))