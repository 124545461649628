import { APIBaseUrl } from '../organon/settings';
import { getCSRF } from "../../utils/crsf";


export function whoami () {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/rest-auth/user/`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        if (r.status === 200) {
          r.json()
            .then(data => resolve({
              "id": data.pk,
              "username": data.username
            }))
            .catch(reject);
        }
        else if (r.status === 403) {
          // User is not authenticated.
          // Resolve with empty.
          resolve(null);
        }
        else {
          reject(r.status);
        }
      })
      .catch(reject);
  }) 
}


export function fetchUsersByName (query) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/users/?search=${query}`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}


export function login (username, password) {
  return new Promise((resolve, reject) => {

    // Alternative if needs a proper form.
    // let formData = new FormData();
    //     formData.append('title', attachment.title);
    //     formData.append('attachment', dataURItoBlob(attachment.url), attachment.filename);

    fetch(`${APIBaseUrl}/rest-auth/login/`,
      {
        'method': 'POST',
        'credentials': 'include',
        'body': JSON.stringify({ username, password }),
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        if (r.status === 200) {
          resolve(true);
        }
        else {
          reject(r.status);
        }
      })
      .catch(reject);
  });
}

export function logout () {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/rest-auth/logout/`,
      {
        'method': 'POST',
        'credentials': 'include',
        'headers': {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        if (r.status === 200) {
          resolve(true);
        }
        else {
          reject(r.status);
        }
      })
      .catch(reject);
  });
}

export default { fetchUsersByName, whoami, login, logout }