import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectScores, fetchScoreList } from './scoreListSlice';
import { getTranslationsState } from '../score/translationsSlice';
import { FETCH_STATUSES } from './constants';
import { Link } from 'react-router';
import { translate as t } from '../../utils/translate';
import * as dayjs from 'dayjs'
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

export function ScoreList () {
  const dispatch = useDispatch(),
        scoreList = useSelector(state => state.scoreList),
        scores = useSelector(selectScores),
        selectedLanguage = useSelector(getTranslationsState).selectedLanguage;

  dayjs.extend(advancedFormat)
  dayjs.locale(selectedLanguage)


  useEffect(() => {
    if (scoreList.status === FETCH_STATUSES.IDLE)  {
      dispatch(fetchScoreList());
    }
  }, [scoreList.status, dispatch]);

  if (scoreList.status === FETCH_STATUSES.IDLE) {
    return <>Initiating</>;
  }
  else if (scoreList.status === FETCH_STATUSES.PENDING) {
    return <>Loading score</>;
  } else if (scoreList.status === FETCH_STATUSES.FAILED) {
    return <>Loading score failed</>;
  }
  else {
    return <ul style={ { listStyleType: 'none', margin: 0, padding: 0 } }>
      { scores.map(score => <li className="scores__item">
              <Link to={ `/partitions/${ score.id }` } className="item__title">
                { score.title }
            </Link> — <span className="item__meta">{ t('par') } { score.score_author }, { t('le') } { dayjs(score.updated_at).format('Do MMMM YYYY') } <a href={ `organon.pw/partitions/${ score.id }` }>(v1)</a></span>
            </li> )}
    </ul>
  }
}