import { VALID_CONTINGENT_MODES, VALID_LINK_MODES, VALID_TRANSITIONS, VALID_LINE_TYPES, SCORE_LANGUAGE_VALID_VALUES } from './constants'

export const isValidLineType = (v) => VALID_LINE_TYPES.indexOf(v) > -1

export const isValidLinkMode = (v) => VALID_LINK_MODES.indexOf(v) > -1

export const isValidTransition = (v) => VALID_TRANSITIONS.indexOf(v) > -1

export const isValidContingentMode  = (v) => VALID_CONTINGENT_MODES.indexOf(v) > -1

export const isValidLanguage = (v) => SCORE_LANGUAGE_VALID_VALUES.indexOf(v) > -1