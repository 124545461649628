import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import Sidebar from './features/organon/Sidebar';
import { selectCurrentUserStatus, fetchCurrentUser  } from "./features/organon/organonSlice";
import { FETCH_STATUSES } from "./features/score/constants";



function App() {
  const dispatch = useDispatch(),
        user_fetch_status = useSelector(selectCurrentUserStatus);

  useEffect(() => {
    // Initial start logic.

    if (user_fetch_status === FETCH_STATUSES.IDLE) {
      dispatch(fetchCurrentUser());
    }
  }, [user_fetch_status])

  return (
    <div className="App" style={ { display: 'flex', position: 'absolute', inset: 0, overflow: 'hidden'  } }>
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default App;
