import React from "react";
import { ScoreList } from "../score/ScoreList";
import { MyScoreList } from "../score/MyScoreList";
import { translate as t } from '../../utils/translate';


export default function Home () {
  return <div style={ { width: '100%', padding: '2ch', boxSizing: 'border-box', overflow: 'auto', height: '100%' } } >
    <div style={ { display: 'flex' } }>
      <div style={ { flex: '1 1'} } >
        <h2>{ t('Partitions') }</h2>
        <ScoreList />
      </div>
      <div style={ { flex: '1 1'} }>
        <h2>{ t('Mes Partitions') }</h2>
        <MyScoreList />
      </div>
    </div>
  </div>
  
}