import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LANGUAGE, FETCH_STATUSES } from '../score/constants';
import { getTranslationsState, selectTranslation } from '../score/translationsSlice';

export function LanguageSelector () {
  const selectedLanguage = useSelector(getTranslationsState).selectedLanguage,
        status = useSelector(getTranslationsState).status,
        dispatch = useDispatch();

  useEffect(() => {
    if (status === FETCH_STATUSES.IDLE)  {
      dispatch(selectTranslation(DEFAULT_LANGUAGE));
    }
  }, [status, dispatch]);
    
  if (selectedLanguage == 'fr') {
    return <span className="link__faux" onClick={ () => dispatch(selectTranslation('en')) }>English</span>
  }
  else {
    return <span className="link__faux" onClick={ () => dispatch(selectTranslation('fr')) }>Français</span>
  }

  // return <div className="languageSelector">
    
  //   <span >
  //     { (selectedLanguage == 'fr') && '[x]'} FR
  //   </span>
  //   -
  //   <span onClick={ () => dispatch(selectTranslation('en')) }>
  //     { (selectedLanguage == 'en') && '[x]'} EN
  //   </span>
  // </div>
}