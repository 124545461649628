import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';
import { LanguageSelector } from './LanguageSelector';
import { translate as t } from '../../utils/translate';
import { FETCH_STATUSES } from '../score/constants';
import { selectCurrentUser, selectCurrentUserStatus, logout, login, selectLoginStatus, resetLoginStatus } from './organonSlice';

function LoginForm ({ onClose }) {
    const dispatch = useDispatch(),
          loginStatus = useSelector(selectLoginStatus);
    
    return <form id="form__login" onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(login({
            username: formData.get('username'),
            password: formData.get('password') 
        }))
    }}>
        <label>
            { t('Username') }
            <input type="text" name="username"/>
        </label>
        <label>
            { t('Password') }
            <input type="password" name="password"/>
        </label>
        { loginStatus === FETCH_STATUSES.FAILED && <p>
            { t('Login failed - please try again') }
        </p>}
        <span className="link__faux" onClick={ () => onClose() }>cancel</span> <button type="submit" disabled={ loginStatus === FETCH_STATUSES.PENDING }>{ t('login') }</button>
    </form>
}

function CurrentUserControl () {
    const [showLoginForm, setShowLoginForm] = useState(false),
          dispatch = useDispatch(),
          fetchStatus = useSelector(selectCurrentUserStatus),
          currentUser = useSelector(selectCurrentUser);

    if (fetchStatus === FETCH_STATUSES.FULFILLED) {
        if (currentUser) {
            return <>
                { t('Bienvenue') } { currentUser.username }
                <span className="link__faux" onClick={ () => dispatch(logout()) }>(←)</span>
            </>
        }
        else if (showLoginForm) {
            return <LoginForm onClose={ () => {
                setShowLoginForm(false);
                dispatch(resetLoginStatus());
            } } />
        }
        else {
            return <span className="link__faux" onClick={ () => setShowLoginForm(true) }>Login</span>
        }
    }
    else if (fetchStatus === FETCH_STATUSES.PENDING) {
        return <>Loading.</>
    }
    else if (fetchStatus === FETCH_STATUSES.FAILED) {
        return <>Could not load.</>
    }
    else if (fetchStatus === FETCH_STATUSES.IDLE) {
        return <>Idle.</>
    }
}

export default function Sidebar () {
  return <details class="panel__collapsable" data-align="right" open>
    <summary>
        Menu
    </summary>
    <div class="main-header__content content">
        <div class="main-header__logo logo">
            <h1 class="logo__name"><Link to="/" className="logo_link">{ t('Organon') }</Link></h1>
        </div>

        <nav class="main-header__menu main-header__menu--primary menu menu--primary">
            {/* <ul class="menu__list">
                <li class="menu__item"><a href="/la-notation-w/">The W notation</a></li>
                <li class="menu__item"><a href="/partitions/">Scores directory</a></li>
            </ul> */}
        </nav>

        <div id="create" class="main-header__create create"></div>

        <nav class="main-header__user">
            <CurrentUserControl />
        </nav>
        <LanguageSelector />
        {/* <nav class="main-header__menu main-header__menu--secondary menu menu--inline">
            <ul class="menu__list">
                
                <li class="menu__item"><a href="#" class="js-lang" data-lang="fr">Français</a></li>
                
                <li class="menu__item"><a href="/credits/">Credits</a></li>
                <li class="menu__item"><a href="http://1110111.org">Site W</a></li>
            </ul>
        </nav> */}
    </div>
  </details>
  

}