import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchScoreById } from '../score/scoreSlice';
import { FETCH_STATUSES } from '../score/constants';
import userApi from '../score/userApi';

export const fetchCurrentUser = createAsyncThunk(
  'organon/fetchCurrentUser',
  async () => {
    const response = await userApi.whoami();
    return response;
  }
)

export const logout = createAsyncThunk(
  'organon/logout',
  async () => {
    const response = await userApi.logout();
    return response;
  }
)

// First try to authenticate user
// then fetch user details
export const login = createAsyncThunk(
  'organon/login',
  async ({ username, password }, { dispatch }) => {
    const response = await userApi.login(username, password);

    if (response) {
      dispatch(fetchCurrentUser());
    }
  }
)

const initialState = {
  user: {
    user: null,
    status: {
      fetch: FETCH_STATUSES.IDLE,
      login: FETCH_STATUSES.IDLE,
      logout:  FETCH_STATUSES.IDLE
    }
  }
};

export const organonSlice = createSlice({
  name: 'organon',
  initialState,
  reducers: {
    setPanel: (state, action) => {
      state.panel = action.payload
    },
    resetLoginStatus: (state, action) => {
      state.user.status.login = FETCH_STATUSES.IDLE;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScoreById.pending, (state, action) => {
      state.panel = 'score'
    });
    builder.addCase(fetchCurrentUser.pending, (state, action) => {
      state.user.status.fetch = FETCH_STATUSES.PENDING;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user.status.fetch = FETCH_STATUSES.FULFILLED;
      state.user.user = action.payload;
    });
    builder.addCase(fetchCurrentUser.rejected, (state, action) => {
      state.user.status.fetch = FETCH_STATUSES.FAILED;
      state.user.user = null;
    });
    builder.addCase(login.pending, (state, action) => {
      state.user.status.login = FETCH_STATUSES.PENDING;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user.status.login = FETCH_STATUSES.FULFILLED;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user.status.login = FETCH_STATUSES.FAILED;
    });
    builder.addCase(logout.pending, (state, action) => {
      state.user.status.logout = FETCH_STATUSES.PENDING;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user.status.logout = FETCH_STATUSES.FULFILLED;
      state.user.user = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.user.status.logout = FETCH_STATUSES.FAILED;
    });
  }
});

export const selectPanel = (state) => state.organon.panel;

export const selectCurrentUser = (state) => state.organon.user.user;

export const selectCurrentUserStatus = (state) => state.organon.user.status.fetch;

export const selectLoginStatus = (state) => state.organon.user.status.login;

export const selectLogoutStatus = (state) => state.organon.user.status.logout;

export const { setPanel, resetLoginStatus } = organonSlice.actions;

export default organonSlice.reducer